@import "../../../node_modules/bootstrap/scss/functions";

$primary: #151E45;
$danger: #DB0061;
$font-family-sans-serif: Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$border-width: 2px;

// button

$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
$btn-font-size-lg: 18px;

// input/button

$input-btn-focus-width:         .125rem;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color:         tint-color($primary, 75%);
$input-btn-focus-blur:          4;
$input-btn-focus-box-shadow:    0 2 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

// form control

$input-border-radius: 6px;
$input-bg: rgba(21, 30, 69, 0.03);

// card

$card-border-radius: 6px;

@import "../../../node_modules/bootstrap/scss/bootstrap";


.btn-primary {
  @include button-variant($primary, $primary, color-contrast($primary), #fff);
}

.btn-secondary {
  @include button-variant($secondary, $secondary, color-contrast($secondary), #fff);
}

.btn-success {
  @include button-variant($success, $success, color-contrast($success), #fff);
}

.btn-danger {
  @include button-variant($danger, $danger, color-contrast($danger), #fff);
}
